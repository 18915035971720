.cta {
  padding: $space-4 0;
  @media screen and (max-width: 575px) {
    padding: $space-3 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 67%;
    margin: 0 auto;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &__pretitulo {
    font-family: $typo-alternative;
    font-size: $header-1-res;
    color: $color-secondary;
    @media screen and (max-width: 1199px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-1;
    color: $color-grey-3;
    margin-bottom: $space-01;
    @media screen and (max-width: 1199px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 810px) {
      font-size: $header-2-res;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: $space-1;
      // font-size: $header-2-res;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}

.post-type-archive-perros,
.post-type-archive-gatos,
.single-perros,
.single-gatos,
.page-template-page-todos-animales,
.blog,
.category {
  .cta {
    background-color: $color-grey-6;
  }
}
