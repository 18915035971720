.hero {
  position: relative;
  overflow: hidden;
  background-color: $color-fondo-hero;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  &__img {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: $trans;
    // @media screen and (max-width: 767px) {
    //   object-position: bottom right;
    // }
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;

    background: linear-gradient(
      47deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    @media screen and (max-width: 767px) {
      background: $color-overlay;
      opacity: 0.4;
    }
  }

  &__container {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 50%;
    padding-top: $space-6 + $space-4;
    padding-bottom: $space-6;
    color: $color-white;

    @media screen and (max-width: 1240px) {
      width: 55%;
      transition: $trans;
    }

    @media screen and (max-width: 991px) {
      padding-top: $space-6 + $space-1;
      padding-bottom: $space-5;
      transition: $trans;
    }

    @media screen and (max-width: 890px) {
      padding-top: $space-6;
      padding-bottom: $space-5;
      transition: $trans;
    }

    @media screen and (max-width: 767px) {
      width: 65%;
      padding-top: $space-5;
      padding-bottom: $space-5;
      transition: $trans;
    }
    @media screen and (max-width: 690px) {
      width: 80%;
      padding-top: $space-5 + $space-4;
      padding-bottom: $space-1;
      transition: $trans;
    }
    // @media screen and (max-width: 600px) {
    //   width: 100%;
    //   transition: $trans;
    // }
    @media screen and (max-width: 480px) {
      width: 100%;
      transition: $trans;
    }
  }

  &__pretitulo {
    font-family: $typo-alternative;
    font-size: $header-1-res;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    color: $color-accent-light;
    @media screen and (max-width: 1199px) {
      font-size: $header-2;
    }

    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
      margin-bottom: $font-size-smaller;
      color: $color-white;
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-1;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    margin-bottom: $space-2;

    @media screen and (max-width: 1199px) {
      font-size: $header-1-res;
      br {
        display: none;
      }
    }
    @media screen and (max-width: 810px) {
      font-size: $header-2-res;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: $font-size;
      // font-size: $header-2-res;
    }
  }

  &__actions {
    .boton--secondary {
      margin-right: 1rem;
      @media screen and (max-width: 767px) {
        margin-right: 0.5rem;
        transition: $trans;
      }
      @media screen and (max-width: 575px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
