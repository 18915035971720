.footer {
  background-color: $color-accent-dark !important;
  color: $color-white;
  &__header {
    padding: $space-2 0;
    border-bottom: 1px dashed $color-white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      gap: $space-1;
    }
  }

  &__header-item {
    max-width: 50%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__header-item--urgente {
    @media screen and (max-width: 767px) {
      max-width: 70%;
    }
    @media screen and (max-width: 650px) {
      max-width: 100%;
      text-align: center;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__header-item--actions {
    p {
      display: flex;
      gap: $space-05;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      @media screen and (max-width: 650px) {
        flex-direction: row;
      }
    }
  }

  &__content {
    padding: $space-2 0;
    border-bottom: 1px dashed $color-white;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
    }
  }

  &__item {
  }

  &__item--contacto {
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: $space-01;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        a {
          color: $color-white;
          text-decoration: none;
          &:hover {
            color: $color-grey-5;
          }
        }
      }
    }
  }

  &__item--mascotas {
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li a {
        color: $color-white;
        text-decoration: none;
        &:hover {
          color: $color-grey-5;
        }
      }
    }
  }

  &__item--social {
    @media screen and (max-width: 767px) {
      grid-column: 1 / span 2;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3;
    }
    @media screen and (max-width: 650px) {
      grid-column: 1 / span 1;
      display: flex;
      flex-direction: column;
    }
    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
      margin-bottom: $space-1;
      @media screen and (max-width: 650px) {
        justify-content: center;
        margin-bottom: 0;
      }
    }
    .social-icon {
      font-size: 28px !important;
      color: $color-white !important;

      @media screen and (max-width: 480px) {
        font-size: 32px !important;
      }
      &:hover {
        color: $color-grey-5 !important;
      }
    }
  }

  &__footer {
    padding: $space-1 0 $space-3 0;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    @media screen and (max-width: 650px) {
      align-items: center;
      text-align: center;
    }
  }

  &__footer-item {
  }

  &__footer-item--copy {
  }

  &__footer-item--legal {
  }
  .widget-footer__title {
    font-size: $header-3;
  }
}

.nav-legal {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    gap: $space-1;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: $space-05;
      align-items: center;
    }
    & > li.menu-item {
      a {
        color: $color-white;
        font-weight: $font-weight-medium;
        font-size: $font-size-small;
        text-decoration: none;
      }
    }
  }
}

.widget-footer {
  &__title {
    font-family: $typo-secondary;
    font-size: $font-size-bigger;
  }
  p {
    color: $color-white;
  }
}
