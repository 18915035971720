body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;
}

a {
  font-family: $typo-primary;
  color: $color-accent;
  text-decoration: none;

  transition: $trans;
  &:hover {
    color: $color-accent-dark;
    text-decoration: none;
    transition: $trans;
  }
}

body.admin-padding-top {
  --height: 2rem;
  .pre-header {
    margin-top: var(--height);
  }
  .header {
    top: calc(2rem + var(--height));
  }
  .hero {
    top: var(--height);
  }
}
