.page-title {
  color: $color-white;

  &__content {
    padding: $space-6 0 $space-2 0;
    background-color: $color-accent;
    @media screen and (max-width: 575px) {
      padding: $space-5 0 $space-1 0;
    }
  }

  &__h {
    font-family: $typo-secondary;
    font-size: $header-1;
    @media screen and (max-width: 810px) {
      font-size: $header-1-res;
    }
  }

  &__sub {
    font-size: $header-3;
  }

  &__breadcrumbs {
    padding: $space-01 0;
    color: $color-grey-3;
    background-color: $color-grey-6;
    font-size: $font-size-small;
    a {
      color: $color-accent;
      text-decoration: none;
    }
  }
}

.post-type-archive,
.page-template-page-nosotros,
.page-template-page-contacto,
.page-template-page-todos-animales,
.page-template-page-legal,
.blog,
.category,
.single-post {
  .page-title__breadcrumbs {
    background-color: transparent;
  }
}

.single-post {
  .page-title__breadcrumbs {
    text-align: center;
  }
}
