.ff-stream {
  padding: 0 !important;
  min-height: 100% !important;
  background-color: transparent !important;
}
.ff-stream-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
.ff-item {
  box-shadow: $box-shadow;
}
.ff-loadmore-wrapper {
  visibility: hidden !important;
  display: none;
}

.ff-slideshow .ff-supports-comments .ff-comments-list {
  //display: none !important;
}

.ff-truelayout-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  li {
    height: max-content;
    margin-top: 0 !important;
    .ff-item-cont {
      display: none !important;
    }
    .ff-item-header {
      display: none !important;
    }
  }
}
