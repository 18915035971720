.card-venta {
  display: flex;
  // gap: $font-size;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  text-align: center;

  &__image {
    position: relative;
    margin-bottom: $font-size;
    img {
      aspect-ratio: 1.625;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__tags {
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  &__caracteristicas {
    left: 118px;
    background-color: $color-accent;
    padding: 2px 6px;
    border-radius: 3px;
    color: $color-white;
    font-size: $font-size-small;
    width: max-content;
    margin: 0 auto;
  }

  &__title {
    text-decoration: none;
    font-family: $typo-secondary;
    font-size: $header-3;
    color: $color-dark;
  }
}

.feat {
  &__item {
    display: flex;
    margin-bottom: $space-4;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-3;
    }
    @media screen and (max-width: 810px) {
      margin-bottom: $space-2;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1.5fr 1fr;
    }
    @media screen and (max-width: 991px) {
    }
    @media screen and (max-width: 810px) {
      grid-template-columns: 1fr;
    }

    &--inverted {
      @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1.5fr;
      }

      .feat__card-video {
        order: 2;
        @media screen and (max-width: 810px) {
          order: 1;
        }
      }

      .feat__card-picture {
        order: 2;
        @media screen and (max-width: 810px) {
          order: 1;
        }
      }
      .feat__card-content {
        order: 1;
        padding: 0 4rem 0 0;
        @media screen and (max-width: 991px) {
          padding: 0 2rem 0 0;
        }
        @media screen and (max-width: 810px) {
          padding: 1.5rem 0;
          order: 2;
          padding: 0.2rem 0;
          @media screen and (max-width: 810px) {
          }
        }
      }
    }
  }

  &__card-video {
    position: relative;
    @media screen and (max-width: 810px) {
      margin-bottom: 0.5rem;
    }
    iframe {
      aspect-ratio: 16 / 10;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
    }
  }

  &__card-picture {
    position: relative;
    @media screen and (max-width: 810px) {
      margin-bottom: 0.5rem;
    }
    img {
      position: relative;
      aspect-ratio: 16 / 11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 4rem;
    @media screen and (max-width: 991px) {
      padding: 0 0 0 2rem;
    }
    @media screen and (max-width: 810px) {
      padding: 1.5rem 0;
      padding: 0.2rem 0;
    }
  }

  &__card-pretitle {
    font-family: $typo-alternative;
    font-size: $header-2-res;

    font-weight: $font-weight-medium;
    color: $color-accent;
    margin-bottom: $space-05;
    @media screen and (max-width: 575px) {
      font-size: $header-3;
    }
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-2;
    font-weight: $font-weight-medium;
    line-height: 1.4;
    color: $color-dark;
    margin-bottom: 1rem;
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      margin-bottom: 1rem;
    }
  }

  &__card-description {
    line-height: 1.5;
  }
}
