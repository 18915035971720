.animal {
  background-color: $color-grey-6;
  padding: $space-1 0 $space-3 0;

  &__desktop {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__mvl {
    @media screen and (min-width: 481px) {
      display: none;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      gap: $space-2;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__image {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
  }

  &__comprar {
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-3;
    color: $color-accent;
    margin-bottom: $space-05;
  }
  &__descripcion {
  }

  &__contacto {
  }
  &__accordions {
    padding: $space-2 0;
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }
    .accordion-item {
      margin-bottom: $space-01;
      background-color: transparent;
      border-bottom: none;
    }
    .accordion-button {
      border-radius: $space-05 !important;
      color: color-dark;
      font-weight: $font-weight-medium;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231e2325'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
      &:focus {
        border-color: transparent;
        outline: 0;
        box-shadow: none;
      }
    }
    .accordion-button:not(.collapsed) {
      color: $color-accent-dark;
      background-color: $color-white;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234f0080'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    .accordion-body {
      font-size: $font-size-small;
      color: $color-grey-3;
    }
  }
}

.galeria-videos {
  margin-bottom: $space-4;

  &__content {
    display: grid;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr !important;
    }
    &--1 {
      grid-template-columns: 1fr;
    }

    &--2,
    &--4,
    &--6,
    &--8 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &--3,
    &--5,
    &--7 {
      .galeria-videos__item:nth-last-child(1) {
        grid-column: 1 / span 2;
        @media screen and (max-width: 767px) {
          grid-column: 1 / span 1;
        }
      }
    }

    &--2-pares {
    }
    &--2-impares {
    }
  }

  &__item {
    iframe {
      aspect-ratio: 16/9;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius-video;
      box-shadow: $box-shadow;
    }
  }
}

.galeria {
  padding-top: $space-3;

  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 4px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__content-item {
    @media screen and (min-width: 768px) {
      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/3;
      }
      &:nth-child(7) {
        grid-column: 2/4;
        grid-row: 3/5;
      }
      &:nth-child(13) {
        grid-column: 3/5;
        grid-row: 5/7;
      }
      &:nth-child(17) {
        grid-column: 2/4;
        grid-row: 7/9;
      }
      &:nth-child(21) {
        grid-column: 1/3;
        grid-row: 9/11;
      }
      &:nth-child(27) {
        grid-column: 2/4;
        grid-row: 11/13;
      }
      &:nth-child(33) {
        grid-column: 3/5;
        grid-row: 13/ 15;
      }
      &:nth-child(37) {
        grid-column: 2/4;
        grid-row: 15 / 17;
      }
      &:nth-child(41) {
        grid-column: 1/3;
        grid-row: 17/19;
      }
      &:nth-child(47) {
        grid-column: 2/4;
        grid-row: 19/21;
      }
      &:nth-child(53) {
        grid-column: 3/5;
        grid-row: 21/23;
      }
      &:nth-child(57) {
        grid-column: 2/4;
        grid-row: 23/25;
      }
      &:nth-child(61) {
        grid-column: 1/3;
        grid-row: 25/27;
      }
    }
  }

  &__content-img {
    aspect-ratio: 16/11;
    width: 100%;
    object-fit: cover;
  }
}

.mas-info {
  padding: $space-4 0;
  @media screen and (max-width: 575px) {
    padding: $space-3 0;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-4;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-3;
    color: $color-secondary;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-01;
    }
  }

  &__descripcion {
    color: $color-grey-3;
  }

  &__contacto {
  }
}

.animal-venta {
  padding: $space-3 0;
}
