.pre-header {
  background-color: $color-accent-dark;
  padding: 0.55rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  transition: $trans;

  @media screen and (max-width: 890px) {
    display: none;
    transition: $trans;
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    gap: $space-1;
  }

  &__item {
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 0.8375rem;
    i {
      margin-right: 0.5rem;
    }
    a {
      color: $color-white;
    }
  }

  &__item--tel {
  }

  &__item--whats {
  }

  &__item--mail {
  }
}

.header {
  padding: $font-size 0;
  background-color: transparent;
  position: fixed;
  width: 100%;
  top: 2rem;
  z-index: 99;
  transition: $trans;
  @media screen and (max-width: 890px) {
    top: 0;
    transition: $trans;
  }
  @media screen and (max-width: 575px) {
    padding-top: $font-size-smaller;
    padding-bottom: $font-size-smaller;
  }

  &--sticky {
    padding: $space-05 0;
    transition: $trans;
    background-color: $color-secondary-dark;
    box-shadow: $box-shadow;

    .logo {
      width: 180px !important;
      transition: $trans;
      @media screen and (max-width: 480px) {
        width: 160px !important;
        transition: $trans;
      }
      @media screen and (max-width: 400px) {
        width: 150px !important;
        transition: $trans;
      }
    }
    .header__burger {
      i {
        font-size: 2.2rem;
        transition: $trans;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 480px) {
      gap: $space-05;
    }
    @media screen and (max-width: 375px) {
      gap: 0.1rem;
    }
  }

  &__burger {
    i {
      color: $color-white;
      font-size: 2.5rem;
      transition: $trans;
      &:hover {
        color: $color-grey-5;
      }
      @media screen and (max-width: 480px) {
        font-size: 2.25rem;
      }
    }
    line-height: 1;
    // @media screen and (min-width: 1221px) {
    //   display: none;
    //   transition: $trans;
    // }
  }

  &__logo {
    .logo {
      width: 220px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 200px;
        transition: $trans;
      }
      @media screen and (max-width: 480px) {
        width: 160px;
        transition: $trans;
      }
      @media screen and (max-width: 400px) {
        width: 146px;
        transition: $trans;
      }
    }
  }

  &__nav {
    margin-left: auto;
    @media screen and (max-width: 890px) {
      display: none;
      transition: $trans;
    }
  }

  &__contacto {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    @media screen and (min-width: 891px) {
      display: none;
    }
    @media screen and (max-width: 890px) {
      margin-left: auto;
      transition: $trans;
    }
  }
  &__contacto-items {
    color: $color-white;
    display: flex;
    flex-direction: column;
    gap: $space-05;
    @media screen and (max-width: 480px) {
      font-size: $font-size;
      gap: 0.25rem;
    }
    @media screen and (max-width: 400px) {
      font-size: $font-size-small;
      gap: 0.2;
    }
    i {
      border-radius: $space-4;
      padding: 4px;
      line-height: 1;
      width: $space-1;
      height: $space-1;
      @media screen and (max-width: 480px) {
        width: auto;
        height: auto;
        padding: 6px;
      }
      @media screen and (max-width: 400px) {
        padding: 5px;
      }
      // @media screen and (max-width: 365px) {
      //   display: none;
      // }
      &.bi-telephone {
        background-color: $color-white;
        color: $color-dark;
        font-size: 0.75rem;
        padding-left: 0.35rem;
        padding-top: 0.35rem;
      }
      &.bi-whatsapp {
        background-color: $color-whatsapp-light;
      }
    }
    a {
      color: $color-white;
      &:hover {
        color: $color-grey-5;
      }
    }
  }
  &__contacto-item-telf {
    @media screen and (max-width: 790px) {
      display: none;
    }
  }
}
