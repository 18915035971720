.venta-todos {
  background-image: url("../../dist/assets/site/fondo-rosa-home.jpg");
  background-position: center center;
  background-size: cover;
  transition: $trans;
  @media screen and (max-width: 767px) {
    display: none;
  }

  // @media screen and (max-width: 575px) {
  //   background: none;
  //   padding: $space-3 0;
  //   transition: $trans;
  // }
  &__content {
    display: flex;
    gap: 2.5rem;
    transform: translateY(-65px);
    position: relative;
    z-index: 9;
    transition: $trans;

    // @media screen and (max-width: 575px) {
    //   flex-direction: column;
    //   transform: translateY(0px);
    //   transition: $trans;
    // }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 470px;
    transition: $trans;

    @media screen and (max-width: 991px) {
      max-width: 45%;
      transition: $trans;
    }
    // @media screen and (max-width: 575px) {
    //   max-width: 100%;
    //   transition: $trans;
    //   align-items: center;
    // }
    img {
      aspect-ratio: 2/1;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__title {
    font-family: $typo-secondary;
    font-size: $header-2;
    color: $color-white;
    text-decoration: none;
    // @media screen and (max-width: 575px) {
    //   color: $color-dark;
    // }
    &:hover {
      color: $color-white;
      // @media screen and (max-width: 575px) {
      //   color: $color-dark;
      // }
    }
  }
}

.home-venta {
  &--perros {
    padding: $space-3 0;
  }
  &--gatos {
    padding: $space-3 0;
  }
  @media screen and (max-width: 991px) {
    &--perros {
      padding: $space-3 0 $space-02 0;
    }
    &--gatos {
      padding: $space-02 0 $space-3 0;
    }
  }
}

.venta-p {
  &__content {
  }

  &__container--res {
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 3rem 1.875rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .card-venta {
      grid-column: span 2;
    }
  }

  &__grid--1 {
    .card-venta:nth-last-child(1) {
      grid-column: 3 / span 2;
      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &__grid--2 {
    .card-venta:nth-last-child(2) {
      grid-column: 2 / span 2;
      @media screen and (max-width: 991px) {
        grid-column: 3 / span 2;
      }
      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
    .card-venta:nth-last-child(1) {
      grid-column: 4 / span 2;

      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &__slider {
    @media screen and (min-width: 992px) {
      display: none !important;
    }
    .slick-dots li {
      margin: 0 !important;
    }
    .card-venta {
      padding: 0 $space-01;
    }
  }
}

.instagram {
  padding: $space-4 0;
  @media screen and (max-width: 575px) {
    padding: $space-3 0;
  }
  background-color: $color-grey-6;

  &__content {
  }
}

.ubicacion {
  padding: $space-4 0;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  iframe {
    width: 100%;
    height: 550px;
    @media screen and (max-width: 767px) {
      height: 460px;
    }
    @media screen and (max-width: 575px) {
      height: 400px;
    }
    @media screen and (max-width: 480px) {
      height: 320px;
    }
  }
}

.opiniones {
  padding: $space-2 0 $space-5 0;
}

.sfeat {
  padding: 0 0 $space-5 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: $space-01 $space-4;
  position: relative;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 480px) {
    padding-top: $space-4;
    padding-bottom: $space-4;
  }
  &__picture {
    position: relative;
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
  }

  &__pic {
    position: relative;
    aspect-ratio: 16/11;
    height: auto;
    box-shadow: $box-shadow-small;
    border-radius: 16px;
    &--1 {
      z-index: 1;
      width: 130%;
      // max-width: 600px;
      // align-self: flex-end;
      // margin-right: 12rem;
      // @media screen and (max-width: 1280px) {
      //   margin-right: 5rem;
      // }
      // @media screen and (max-width: 991px) {
      //   align-self: flex-start;
      //   margin-right: 0;
      //   margin-left: 4rem;
      // }
      // @media screen and (max-width: 767px) {
      //   max-width: 480px;
      // }
    }
    &--2 {
      width: 140%;
      // max-width: 490px;
      z-index: 2;
      // align-self: flex-end;
      margin-left: -40%;
      // margin-right: 1rem;
      margin-top: 112%;
      // @media screen and (max-width: 991px) {
      //   align-self: flex-start;
      //   margin-right: 0;
      //   margin-left: 36%;
      // }
      // @media screen and (max-width: 767px) {
      //   max-width: 360px;
      // }
    }
  }

  &__expo {
    position: relative;
    right: 0;
    top: 5rem;
    z-index: 3;
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    // @media screen and (max-width: 1280px) {
    //   right: -30px;
    // }
    @media screen and (max-width: 991px) {
      //right: 6rem;
      grid-column: 1 / span 1;
      margin-left: 55%;
    }
    @media screen and (max-width: 650px) {
      margin-left: 50%;
    }
    @media screen and (max-width: 575px) {
      top: 4rem;
      margin-left: 45%;
    }
    @media screen and (max-width: 480px) {
      display: none;
    }

    span {
      font-size: $display-extra-large;
      font-family: $typo-secondary;
      color: $color-white;
      line-height: 1;
      @media screen and (max-width: 575px) {
        font-size: $display-1;
      }
    }
    small {
      margin-top: -$font-size-smaller;
      line-height: 1;
      font-size: $header-2;
      font-weight: $font-weight-bold;
      color: $color-white;
      font-family: $typo-secondary;
      margin-left: 11rem;
      // @media screen and (max-width: 767px) {
      //   width: 20px;
      // }
      @media screen and (max-width: 575px) {
        font-size: $header-2-res;
      }

      // @media screen and (max-width: 480px) {
      //   margin-left: 10rem;
      // }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-white;
  }
  &__pretitulo {
    font-family: $typo-alternative;
    font-size: $header-2-res;
    font-weight: $font-weight-medium;
  }
  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-1;
    font-weight: $font-weight-bold;
    line-height: 1.3;
    margin-bottom: $space-01;
    @media screen and (max-width: 767px) {
      font-size: $header-1-res;
    }
  }
  &__description {
  }
  &__actions {
  }
}
